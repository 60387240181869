<template>
	<article class="layer" style="display:block">
		<div class="layer__dim"></div>
		<!-- [D] 세로 가운데 정렬 레이어 일 경우 layer__wrap--centered 클래스 추가 -->
		<div class="layer__wrap layer__wrap--feed">
			<div class="layer-feed">
				<div class="layer-feed__top">
					<button type="button">
						<i class="icon icon-close--white"></i>
					</button>
				</div>
				<div class="layer-feed__header">
					<h3 class="layer-feed__title">
						주변예약
					</h3>
					<div class="layer-feed__category">
						<!-- [D] 활성회 되었을때 active 클래스 추가 -->
						<a href="#" class="active">
							숙소
							<span class="layer-feed__badge">27</span>
						</a>
						<!--
						<span class="layer-feed__dot"></span>
						<a href="#">
							레저티켓
							<span class="layer-feed__badge">27</span>
						</a>
						-->
					</div>
					<div class="layer-feed__slider">
						<div class="item__body">
							<div class="feed-no">
								<!-- [D] 숙소, 레저티켓 없을 경우 -->
								<div class="box box--reverse">
									주변에 숙소가 없어요
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</article>
</template>